<template>
  <v-container :fluid="true">
    <v-card>
      <v-card-title class="justify-space-between">
        <div class="d-flex align-center">
          Edit offer:
          <span class="ml-2">{{ details ? details.short_key : "" }}</span>
        </div>
        <v-card-actions>
          <v-btn
            color="primary"
            depressed
            @click="onEdit(details.is_draft)"
            :loading="isEditing"
          >
            Save
          </v-btn>
          <v-btn
            color="primary"
            depressed
            @click="onEdit(details.is_draft, true)"
            :loading="isEditing"
          >
            Save & Close
          </v-btn>
          <v-btn
            color="primary"
            v-if="details.is_draft"
            outlined
            depressed
            @click="onEdit(false)"
            :loading="isEditing"
          >
            Save & Publish
          </v-btn>
        </v-card-actions>
      </v-card-title>
      <create-step1 :default-data="details" ref="createOffer" />
      <v-divider class="mx-4"></v-divider>
      <create-step2
        :default-data="details"
        :default-files="files"
        :offer-id="$route.params.id"
        ref="editOffer"
      />
    </v-card>
  </v-container>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { LOAD_DETAILS, EDIT, LOAD_FILES } from "@/store/modules/offers/types";
import { requestStatus } from "@/services/services";
import CreateStep2 from "./components/CreateStep2";
import CreateStep1 from "./components/CreateStep1";

export default {
  name: "Offer",
  components: { CreateStep1, CreateStep2 },
  data: () => ({
    isLoading: false,
    isEditing: false,
  }),
  computed: {
    ...mapState({
      status: (state) => state.offers.status,
      details: (state) => state.offers.details,
      files: (state) => state.offers.files,
    }),
  },
  methods: {
    ...mapActions({
      onLoadDetails: `offers/${LOAD_DETAILS}`,
      edit: `offers/${EDIT}`,
      onLoadFiles: `offers/${LOAD_FILES}`,
    }),

    async onEdit(is_draft, backToList) {
      const first_result = this.$refs.createOffer.onSave();
      if (!first_result) return;
      const second_result = this.$refs.editOffer.onSave();
      if (!second_result) return;

      const response = await this.edit({
        data: { ...first_result, ...second_result, is_draft },
        id: this.$route.params.id,
      });

      if (backToList && response === "success") this.$router.back();
    },
  },
  watch: {
    status(newValue) {
      this.isLoading = newValue.load_details === requestStatus.loading;
      this.isEditing = newValue.edit === requestStatus.loading;
      if (
        newValue.change_field === "edit" &&
        newValue.edit === requestStatus.success
      ) {
        this.$toaster.success("Success edit offer");
        // this.$router.push({name: "offers"});
      }
    },
  },
  created() {
    this.onLoadDetails(this.$route.params.id);
    this.onLoadFiles(`offer=${this.$route.params.id}`);
  },
};
</script>

<style scoped></style>
